<template>
  <div class="content">

    <div class="header d-flex align-items-center profile-header">
      <!-- Mask -->
      <span class="mask bg-gradient-info"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-12">
            <h1 class="display-3 text-white">Create new Lead</h1>
          </div>
        </div>
      </div>
    </div>

    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">{{$route.meta && $route.meta.name ? $route.meta.name : $route.name}}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">

      <div class="row">
        <div class="col-xl-12">
          <template>
            <card>
              <div slot="header" class="row align-items-center"  >
                <div class="col-8">
                  <h3 class="mb-0">Lead Creation Form</h3>
                </div>
                <div class="col-4 text-right">
                  <a href="#" @click="createLead"  class="btn btn-sm btn-primary">Submit</a>
                </div>
              </div>

              <form @submit.prevent="createLead" v-loading="loading">
                <div>
                  <h6 class="heading-small text-muted mb-4">Lead Information</h6>
                  <div>
                    <div class="row">
                      <div class="col-lg-6">
                        <base-input
                          type="text"
                          label="First Name"
                          placeholder="First Name"
                          v-model="lead.first_name"
                        >
                        </base-input>
                      </div>
                      <div class="col-lg-6">
                        <base-input
                          type="text"
                          label="Last Name"
                          placeholder="Last Name"
                          v-model="lead.last_name"
                        >
                        </base-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <base-input
                          type="email"
                          label="Email Address"
                          placeholder="mike@email.com"
                          name="email"
                          v-model="lead.email"
                        >
                        </base-input>
                      </div>
                      <div class="col-lg-6">
                        <base-input
                          type="phone"
                          label="Phone Number"
                          placeholder="0400 123 123"
                          name="phone"
                          v-model="lead.phone"
                        >
                        </base-input>
                      </div>
                    </div>

                  </div>
                  <hr class="my-4">

                  <!-- Address -->
                  <h6 class="heading-small text-muted mb-4">Event Information</h6>

                  <div>

                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="form-control-label">Event Date</label>
                          <date-picker name="date" v-model="lead.event_date" :config="dateOptions"></date-picker>
                        </div>
                      </div>
                      <div class="col-lg-3">

                        <base-input
                          type="text"
                          label="Estimated Budget"
                          placeholder="Estimated Budget"
                          v-model="lead.estimated_budget"
                          input-group-classes="input-group-merge"
                        >
                          <template slot="prepend">$</template>
                        </base-input>
                      </div>
                      <div class="col-lg-3">
                        <base-input
                          type="text"
                          label="Estimated Guests"
                          placeholder="Estimated Guests"
                          v-model="lead.estimated_guests"
                        >
                        </base-input>
                      </div>
                    </div>


                    <div class="row">
                      <div class="col-lg-6">
                        <base-input
                          label="Event Type"
                        >
                          <el-select v-model="lead.event_type" placeholder="Event Type">
                            <el-option
                              v-for="(label, key) in eventTypes"
                              :key="key"
                              :label="label"
                              :value="key">
                            </el-option>
                          </el-select>
                        </base-input>
                      </div>
                      <div class="col-lg-6">
                        <base-input
                          label="Type Of Wedding"
                          v-if="lead.event_type.toLowerCase() === 'wedding'"
                        >
                          <el-select v-model="lead.type_of_wedding" placeholder="Type Of Wedding">
                            <el-option
                              v-for="label in weddingEventTypes"
                              :key="label"
                              :label="label"
                              :value="label">
                            </el-option>
                          </el-select>
                        </base-input>
                        <base-input
                          label="Type Of Social Event"
                          v-if="lead.event_type.toLowerCase() === 'social'"
                        >
                          <el-select v-model="lead.type_of_social_event" placeholder="Type Of Social Event">
                            <el-option
                              v-for="label in socialEventTypes"
                              :key="label"
                              :label="label"
                              :value="label">
                            </el-option>
                          </el-select>
                        </base-input>
                        <base-input
                          label="Type Of Corporate Event"
                          v-if="lead.event_type.toLowerCase() === 'corporate'"
                        >
                          <el-select v-model="lead.type_of_corporate_event" placeholder="Type Of Corporate Event">
                            <el-option
                              v-for="label in corporateEventTypes"
                              :key="label"
                              :label="label"
                              :value="label">
                            </el-option>
                          </el-select>
                        </base-input>
                      </div>

                    </div>

                    <div class="row" v-if="lead.event_type.toLowerCase() === 'wedding'">
                      <div class="col-lg-6">
                        <base-input
                          type="text"
                          label="Partner's First Name"
                          placeholder="Partners First Name"
                          v-model="lead.partner_first_name"
                        >
                        </base-input>
                      </div>
                      <div class="col-lg-6">
                        <base-input
                          type="text"
                          label="Partner's Last Name"
                          placeholder="Partners Last Name"
                          v-model="lead.partner_last_name"
                        >
                        </base-input>
                      </div>
                    </div>


                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label class="form-control-label">Event Details</label>
                          <textarea
                            rows="10"
                            class="form-control"
                            v-model="lead.event_details"
                            placeholder="A few words about your event ...">A few words about your event...</textarea>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </form>
            </card>
            <el-dialog
              :title="formSuccess ? 'Success' : 'Error'"
              :visible.sync="dialogVisible"
              width="30%"
              :class="formSuccess ? 'success-div' : 'error-div' "
            >
              <span v-if="formSuccess">
                New lead created successfully.
              </span>
              <span v-else>
                An error occured during the form submission, please try again.
              </span>
            </el-dialog>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {dialog, Table, TableColumn, Select, Option} from "element-ui";
import datePicker  from "vue-bootstrap-datetimepicker";
import {HTTP} from "@/services";
import {eventTypes, weddingEventTypes, corporateEventTypes, socialEventTypes} from '@/../app.config';
import Vue from "vue";

Vue.use(dialog);

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    datePicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      lead: this.leadDefaults(),
      dateOptions: {
        format: 'DD-MM-YYYY',
      },
      formSuccess: null,
      loading: false,
      dialogVisible: false,
    };
  },
  computed: {
    eventTypes: () => eventTypes,
    weddingEventTypes: () => weddingEventTypes,
    corporateEventTypes: () => corporateEventTypes,
    socialEventTypes: () => socialEventTypes,
  },
  methods: {
    async createLead() {
      this.$store.dispatch('leads/createLead', this.lead).then(() => {
        this.formSuccess = true;
        this.dialogVisible = true;
        this.lead = this.leadDefaults();
      }).catch(() => {
        this.formSuccess = false;
        this.dialogVisible = true;
      });
      this.loading = false;
    },
    leadDefaults() {
      return {
        venue_id: '6395',
        request_type: 'booking',// Above two of them are required in api, need to verify this. putting a static value for testing- MUST REMOVE
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        event_date: '',
        estimated_budget: '',
        estimated_guests: '',
        event_type: '',
        type_of_wedding: '',
        type_of_social_event: '',
        type_of_corporate_event: '',
        partner_last_name: '',
        partner_first_name: '',
        event_details: '',
      };
    },
  },
};
</script>

<style scoped>

</style>
